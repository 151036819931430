@import "../../styles/variables";
.container {
    background-color: #ffffff;
    width: 70%;
    min-width: 420px;
    padding: 35px 50px;
    border-radius: 10px;
    box-shadow: 20px 30px 25px rgba(0, 0, 0, 0.15);
    h1 {
        font-size: 32px;
        text-align: center;
        color: #1c093c;
    }
    p {
        position: relative;
        margin: auto;
        width: 100%;
        text-align: center;
        color: #606060;
        font-size: 14px;
        font-weight: 400;
    }

    form {
        width: 100%;
        position: relative;
        margin: 30px auto 0 auto;

        .row {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-gap: 20px 30px;
            margin-bottom: 20px;

            .column {
                display: flex;
                flex-direction: column;

                label {
                    color: #1c093c;
                    font-size: 14px;
                }

                textarea,
                input {
                    width: 100%;
                    font-weight: 400;
                    padding: 8px 10px;
                    border-radius: 5px;
                    border: 1.2px solid #c4cae0;
                    margin-top: 5px;
                }

                textarea {
                    resize: none;
                }

                textarea:focus,
                input:focus {
                    outline: none;
                    border-color: #fb84ff;
                }

                span {
                    &.error {
                        color: #ff3c3c;
                        font-size: 12px;
                    }
                }
            }

            .columnHCaptcha {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        .footerForm {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                max-width: 250px;
                padding: 13px 45px;
                height: 40px;
                gap: 10px;
                background-color: #e9eb95;
                box-shadow: 10px 51px 94px rgba(0, 0, 0, 0.11), 10px 20.718px 33.6194px rgba(0, 0, 0, 0.0697538), 0px 11.8898px 16.44px rgba(0, 0, 0, 0.0506778),
                    0px 6.99366px 8.20599px rgba(0, 0, 0, 0.0353466), 0px 3.30696px 3.31776px rgba(0, 0, 0, 0.0200187);
                border-radius: 40px;
                border: 0;
                > span,
                > div,
                > .successMessage {
                    opacity: 0;
                    transition: opacity 250ms;
                    display: none;

                    &.showMessage,
                    &.showSuccess,
                    &.showSpinner {
                        opacity: 1;
                        display: inline;
                    }
                }
            }
        }
    }
}
@media (max-width: 1124px) {
    .container {
        form {
            .row {
                .column {
                    input {
                        max-width: unset;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .container {
        width: 100%;
        border-radius: 0;

        form {
            .row {
                .column {
                    input {
                        max-width: unset;
                    }
                }
            }
        }
    }
}
