main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

    section {
        border: 0;
    }

    .header {
        width: 100%;
        height: 60vh;

        position: relative;
        mask-image: linear-gradient(black 70%, transparent 100%);

        .imageContainer {
            width: 100%;
            height: 60vh;
            position: absolute;
            inset: 0;

            .imageHeader {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::before {
                content: "";
                position: absolute;
                inset: 0;
                background-color: rgba(0, 0, 0, 0.6);
            }
        }

        .headerContainer {
            width: 100%;
            height: calc(60vh - 200px);
            padding: 30px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            position: absolute;
            inset: 0;
            line-height: 50px;

            h1,
            h2 {
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            }

            h1 {
                font-size: 60px;
                color: #ed95f5;
            }

            h2 {
                font-size: 40px;
                color: #fff;
                margin-left: 0;
                align-self: unset;
            }
        }
    }

    .formSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        max-width: 50%;
        background-color: rgba(255, 255, 255, 1);
        padding: 30px;
        border-radius: 30px;
        z-index: 1;

        font-size: 18px;

        margin-top: -200px;

        .paragraph {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            text-align: left;

            .infoCurso {
                width: 50%;
                min-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-start;
                border: 1px solid rgb(196, 196, 196);
                border-radius: 10px;
                padding: 40px;
                line-height: 1.2;
                box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                margin-bottom: 30px;
            }

            .aboutCourse {
                align-self: flex-start;
            }

            ul {
                align-self: flex-start;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    list-style-type: none;
                }
            }
        }

        h2 {
            margin: 0;
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            font-size: 25px;
        }

        > form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            width: 100%;

            .row {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2px;

                width: 100%;

                > label {
                    width: 100%;
                    margin-bottom: 0;
                    font-size: 20px;
                }

                > input,
                > textarea {
                    width: 100%;
                    padding: 10px 20px;

                    border: 1px solid #dddddd;
                    border-radius: 50px;

                    font-size: 20px;
                    background-color: #eeeeee;

                    box-shadow: 10px 10px 19px -7px rgba(0, 0, 0, 0.37);
                    -webkit-box-shadow: 10px 10px 19px -7px rgba(0, 0, 0, 0.37);
                    -moz-box-shadow: 10px 10px 19px -7px rgba(0, 0, 0, 0.37);
                }

                > textarea {
                    border-radius: 30px;
                }

                span {
                    &.error {
                        margin: 5px 0 0 20px;
                        color: #ff3c3c;
                        font-size: 14px;
                        align-self: flex-start;
                    }
                }
            }

            .rowDisclaimer {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                width: 100%;

                input {
                    width: 20px;
                    height: 20px;
                }

                label {
                    margin-bottom: 0;
                }
            }

            button {
                width: 300px;
                background-image: linear-gradient(to right, #ff6e7f 0%, #bfe9ff 51%, #ff6e7f 100%);
                margin: 10px;
                padding: 15px 45px;
                text-align: center;
                text-transform: uppercase;
                transition: 0.5s;
                background-size: 200% auto;
                color: #3f3f3f;
                font-weight: bold;
                box-shadow: 0 0 20px #eee;
                border: 0;
                border-radius: 30px;
                display: block;
                align-self: center;

                box-shadow: 10px 10px 19px -7px rgba(0, 0, 0, 0.37);
                -webkit-box-shadow: 10px 10px 19px -7px rgba(0, 0, 0, 0.37);
                -moz-box-shadow: 10px 10px 19px -7px rgba(0, 0, 0, 0.37);
                > span,
                > div,
                > .successMessage {
                    opacity: 0;
                    transition: opacity 250ms;
                    display: none;

                    &.showMessage,
                    &.showSuccess,
                    &.showSpinner {
                        opacity: 1;
                        display: inline;
                    }
                }

                &:hover {
                    background-position: right center;
                    text-decoration: none;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    main {
        .header {
            h1 {
                font-size: 50px;
            }

            h2 {
                font-size: 35px;
            }
        }

        .formSection {
            max-width: 70%;
        }
    }
}

@media (max-width: 1000px) {
    main {
        .header {
            .headerContainer {
                h1 {
                    font-size: 45px;
                }

                h2 {
                    font-size: 30px;
                }
            }
        }

        .formSection {
            max-width: 70%;
        }
    }
}

@media (max-width: 764px) {
    main {
        .header {
            .headerContainer {
                h1 {
                    font-size: 45px;
                }

                h2 {
                    font-size: 30px;
                }
            }
        }

        .formSection {
            max-width: 80%;
        }
    }
}

@media (max-width: 600px) {
    main {
        .header {
            .headerContainer {
                h1 {
                    font-size: 35px;
                }

                h2 {
                    line-height: 30px;
                    font-size: 25px;
                }
            }
        }

        .formSection {
            max-width: 90%;
            padding: 30px;

            margin-top: -200px;

            .paragraph {
                font-size: 16px;

                .infoCurso {
                    width: 100%;
                    min-width: 400px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    align-items: flex-start;
                    border: 0;
                    border-radius: 0;
                    padding: 40px;
                    line-height: 1.2;
                    overflow: hidden;
                    margin-bottom: 30px;
                }
            }

            > form {
                .row {
                    > label {
                        font-size: 16px;
                    }

                    > input {
                        font-size: 16px;
                    }
                }

                .rowDisclaimer {
                    label {
                        font-size: 16px;
                    }
                }

                .btnGrad {
                    font-size: 16px;
                }
            }
        }
    }
}
