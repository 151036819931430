@import "../../styles/variables";

nav {
    --height-navbar: 60px;

    width: 100%;
    height: var(--height-navbar, 60px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    border-bottom: 1px solid #fff;
    color: rgba(255, 255, 255, 1);
    position: sticky;
    top: 0;
    backdrop-filter: blur(10px);
    z-index: 100;
    transform: translate(0, -100%);
    transition: transform 300ms, opacity 300ms;
    background-color: rgba(0, 0, 0, 0.2);

    &.visible {
        transform: translate(0, 0);
    }

    p {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.05em;
        filter: blur(0);
    }

    .imgContainer {
        max-height: 100%;

        img {
            height: 40px;
        }
    }

    .hamburgerCheckbox {
        display: none;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        visibility: visible;

        a {
            text-decoration: none;

            &:visited {
                color: #fff;
            }

            li {
                height: 100%;
                list-style: none;
                cursor: pointer;
                padding: 15px 20px;
                font-weight: 200;
                transition: color 180ms;
                letter-spacing: 0.15em;
                white-space: nowrap;
                font-size: 12px;
                font-weight: bold;
                color: #fff;

                &:hover {
                    color: $primary_fuchsia;
                }
            }
        }

        li {
            &.languages {
                margin-left: 15px;
                padding: 5px 10px;
                font-weight: 200;
                position: relative;
                display: flex;
                flex-direction: column;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 5px;

                .languageCheckbox {
                    display: none;
                }

                label {
                    font-size: 12px;
                    cursor: pointer;
                }

                ul {
                    position: absolute;
                    top: 110%;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                    visibility: hidden;
                    opacity: 0;
                    margin-top: -15px;
                    transition: visibility 150ms, margin-top 150ms ease 100ms, opacity 150ms ease 100ms;
                    z-index: 1;
                    height: auto;
                    overflow: hidden;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 10px;

                    li {
                        list-style: none;
                        padding: 10px 30px;
                        font-weight: 200;
                        min-width: 150px;
                        cursor: pointer;
                        text-align: center;
                        backdrop-filter: blur(30px);

                        &.active {
                            background-color: rgba(0, 0, 0, 0.5);
                            span {
                                font-weight: 400;
                            }
                        }

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                .languageCheckbox:checked ~ ul {
                    visibility: visible;
                    opacity: 1;
                    margin-top: 0;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    nav {
        ul {
            a {
                li {
                    padding: 20px 15px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    nav {
        flex-direction: column;
        height: unset;

        .imgContainer {
            padding-top: 15px;
        }

        p {
            padding: 10px 0;
        }

        ul {
            a {
                li {
                    padding: 10px 15px;
                }
            }
        }
    }
}

@media (max-height: 600px) {
    nav {
        ul {
            a {
                li {
                    padding: 10px;
                    font-size: 1.5em;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    nav {
        position: sticky;
        top: 0;
        left: 0;
        flex-direction: row;
        height: var(--height-navbar, 60px);
        padding-right: 25px;
        backdrop-filter: blur(30px);
        background-color: rgba(0, 0, 0, 0.2);

        .imgContainer {
            padding-top: 0;
        }

        > label {
            margin-bottom: 0;
        }

        ul {
            height: unset;
            position: absolute;
            top: var(--height-navbar, 60px);
            right: 0px;
            left: 100%;
            bottom: 0px;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            background-color: $primary_pink;
            backdrop-filter: blur(30px);
            overflow: hidden;
            overflow-y: hidden;
            opacity: 0;
            padding-top: 50px;
            padding-left: 0;
            transition: opacity 300ms ease-in-out;

            a {
                width: 100%;
                li {
                    padding: 20px;
                    font-size: 2em;
                    text-align: center;
                    color: #333333;
                }
            }

            li {
                &.languages {
                    margin-left: 0;
                    margin-top: 20px;
                    padding: 0;
                    font-weight: 200;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    background-color: rgba(0, 0, 0, 0);
                    border-radius: 5px;
                    width: 100%;
                    text-align: center;

                    .languageCheckbox {
                        display: none;
                    }

                    label {
                        font-size: 1.5em;
                        cursor: pointer;
                        padding: 10px 0;
                    }

                    ul {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        flex-direction: row;
                        margin-top: 0;
                        z-index: 100;
                        height: auto;
                        background-color: unset;
                        border-radius: 0;
                        padding-top: 0;
                        overflow: unset;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        li {
                            width: 50%;

                            &.active {
                                background-color: rgba(0, 0, 0, 0);
                                padding: 0 10px;
                                span {
                                    font-size: 1.2em;
                                }
                            }

                            &:hover {
                                background-color: rgba(0, 0, 0, 0);
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }

        .hamburgerCheckbox {
            &:checked {
                + label {
                    div {
                        > div {
                            &:first-child {
                                transform: rotate(45deg);
                            }

                            &:nth-child(2) {
                                opacity: 0;
                            }

                            &:last-child {
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
            }
        }

        .hamburgerCheckbox:checked ~ ul {
            left: 0;
            opacity: 1;
            transition: opacity 300ms ease-in-out;
        }

        .hamburgerMenu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 5px;
            box-sizing: border-box;
            width: 28px;
            height: 28px;
            cursor: pointer;

            div {
                width: 100%;
                height: 2px;
                background-color: #ffffff;
                margin-bottom: 4px;
                transition: all 250ms;
                transform-origin: left;
                border-radius: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-height: 600px) {
    nav {
        ul {
            a {
                li {
                    padding: 15px;
                    font-size: 1.5em;
                }
            }
            li {
                &.languages {
                    margin-top: 0px;
                    label {
                        font-size: 1em;
                    }
                }
            }
        }
    }
}
