main.main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;

    a {
        text-decoration: none;
    }

    > section {
        width: unset;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        gap: 5px;
        width: 100%;
        padding-bottom: 20px;

        h1,
        h2 {
            font-family: "Montserrat", sans-serif;
        }

        h1 {
            font-size: 30px;
        }

        > h2 {
            color: unset;
            font-size: 16px;
            margin-left: unset;
            margin-bottom: unset;
            display: unset;
            align-items: unset;
            justify-content: unset;
            align-self: flex-end;
        }

        > div.copyEmail {
            background-color: #ea4c89;
            border-radius: 8px;
            border-style: none;
            box-sizing: border-box;
            color: #ffffff;
            cursor: pointer;
            display: inline-block;
            font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: 500;
            height: 40px;
            line-height: 20px;
            list-style: none;
            margin: 0;
            outline: none;
            padding: 10px 16px;
            text-align: center;
            text-decoration: none;
            transition: color 100ms;
            vertical-align: baseline;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;

            &:hover,
            &:focus {
                background-color: #f082ac;
            }
        }
    }

    .userContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        border: 1px solid rgb(196, 196, 196);
        border-radius: 10px;
        padding: 40px;
        line-height: 1.2;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        min-width: 600px;
        overflow: hidden;

        .name {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 5px;
        }

        > span:not(.name) {
            font-size: 14px;
        }

        > article {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            > svg {
                width: 20px;
                height: 20px;
            }

            a {
                color: #000;
            }
        }

        .date {
            margin-top: 5px;
            font-size: 12px;
        }
    }
}

@media (max-width: 768px) {
    main.main {
        .userContainer {
            min-width: 450px;
        }
    }
}

@media (max-width: 600px) {
    main.main {
        width: 100%;
        padding: 0;

        .userContainer {
            min-width: 90%;
            max-width: 90%;
        }
    }
}
