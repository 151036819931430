// Primary Colors
$primary_yellow: #e9eb95;
$primary_pink: #d9abd6;
$primary_fuchsia: #e0339d;
$primary_gray: #d9d9d9;

// Secondary Colors
$secondary_fuchsia: #b3267c;

// Neutral Colors
$white: #ffffff;
$black: #000000;

// Header
$custom_header: 60px;

// ScrollBar
@mixin custom_scrollbar() {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        transition: 0.4s ease-in-out;

        &-track {
            background: #f3f3f3;
            border-radius: 8px;
        }

        &-thumb {
            background-color: rgb(248, 155, 242);
            border-radius: 8px;
            transition: background-color 0.2s;

            &:hover {
                background-color: rgb(247, 46, 233);
            }
        }
    }
}

// Z-index
$zIndex: (
    back: -1,
    neutral: 0,
    basic: 1,
    double: 2,
    triple: 3
);

@function z-index($value) {
    @if (map-has-key($zIndex, $value)) {
        @return map-get($zIndex, $value);
    } @else {
        @error "z-index (#{$value}) doesn't exist.";
    }
}

// Main breakpoints
$break-xs: "(min-width: 600px)";
$break-sm: "(min-width: 768px)";
$break-md: "(min-width: 992px)";
$break-lg: "(min-width: 1080px)";
$break-xl: "(min-width: 1200px)";
$break-xxl: "(min-width: 1366px)";
$break-xxxl: "(min-width: 1920px)";

$max-xs: "(max-width: 600px)";
$max-sm: "(max-width: 768px)";
$max-md: "(max-width: 992px)";
$max-lg: "(max-width: 1080px)";
$max-xl: "(max-width: 1200px)";
$max-xxl: "(max-width: 1366px)";
$max-xxxl: "(max-width: 1920px)";

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,500&family=Satisfy&display=swap");

$primary_font: "Satisfy", cursive;
$secondary_font: "Montserrat", sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $primary_font;
    margin-top: 0;
    margin-bottom: 0;
}

p {
    font-family: $secondary_font;
    margin-top: 0;
    margin-bottom: 0;
}
