footer {
    height: 30dvh;
    // background-color: #152e57;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #fff;
    padding: 16px;
    background-color: #ff9a8b;
    background-image: linear-gradient(90deg, #ff9a8b 0%, #ff6a88 55%, #ff99ac 100%);

    a {
        color: white;
        text-decoration: none;
    }

    .information {
        width: 70%;
        overflow: hidden;
        display: flex;
        flex-direction: row;

        &WithName {
            display: flex;
            flex-direction: column;

            .name {
                font-size: 20px;
                font-weight: bold;
            }

            > div {
                width: 100%;
                padding: 8px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }
        }

        &WithoutName {
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                margin: 20px;
            }
        }

        > div {
            width: 50%;
        }

        svg {
            width: 30px;
            height: 30px;
            fill: #fff;
        }
    }

    @media (max-width: 950px) {
        padding-top: 40px;
        height: 35dvh;

        .information {
            width: 100%;
            flex-direction: column;
            align-items: center;

            > div {
                width: 100%;

                > div {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    @media (max-width: 450px) {
        .information {
            &WithoutName {
                > div {
                    margin: 20px 10px;
                }
            }
        }
    }

    .bottom {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        background-color: #cfcfcf;
        color: #000;

        p {
            font-size: 13px;
        }
    }

    @media (max-width: 768px) {
        .bottom {
            height: 30px;
            font-size: 14px;
        }
    }
}
