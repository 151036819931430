.container {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #d9abd6;

    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: fixed;
        top: 0;

        background-color: #d9abd6;

        width: 90%;
        padding: 50px 5%;

        transition: all 0.3s ease-in-out;

        > svg {
            width: 150px;
            height: 150px;

            margin-left: 45%;
            transition: all 0.3s ease-in-out;
        }

        .userPanel {
            justify-self: flex-end;

            display: flex;
            gap: 40px;

            .user {
                display: flex;
                align-items: center;
                gap: 10px;

                > img {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                }
                > label {
                    font-size: 14px;
                    margin-bottom: 0;
                    text-wrap: nowrap;
                }
            }

            > button {
                &.signOut {
                    background-color: #fbda61;
                    background-image: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);

                    border: 0;
                    color: #303030;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 8px;
                    border-radius: 4px;
                    gap: 10px;
                    text-wrap: nowrap;
                }
            }
        }
    }

    > form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        margin-top: 250px;
        background-color: #d9abd6;

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 10px 14px 26px -6px rgba(126, 125, 125, 0.5);
            margin-bottom: 50px;
            overflow: hidden;

            .menu {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #dfdfdf;

                > span {
                    width: 50%;
                    padding: 8px 16px;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        background-color: #e6e6e6;
                    }

                    &.active {
                        font-weight: bold;
                    }
                }
            }

            .webpageContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 50px;
                overflow: hidden;

                > div {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 30px 50px;

                    label,
                    textarea {
                        width: 50%;
                    }

                    textarea,
                    input[type="checkbox"] {
                        max-width: 400px;
                        padding: 10px 15px;
                        border-radius: 5px;
                    }

                    input[type="checkbox"] {
                        width: 30px;
                        height: 30px;
                    }

                    label {
                        margin-right: 0;
                        text-align: left;
                        display: flex;
                        gap: 5px;

                        .path {
                            padding: 2px 8px;
                            background-color: #e4e4e4;
                            border-radius: 4px;
                            border: 1px solid #dddddd;
                            text-align: center;
                            font-size: 14px;
                        }
                    }

                    &:nth-child(2n) {
                        background-color: rgb(243, 243, 243);
                    }

                    > div {
                        display: flex;
                        flex-direction: column;
                        max-width: 50%;

                        &.image {
                            #imagePreview {
                                max-width: 30%;
                            }
                        }
                    }

                    textarea {
                        height: 100px;
                    }
                }
            }

            .masterclassContainer {
                width: 100%;
            }
        }

        > button {
            background: #e73b3b;
            border-radius: 999px;
            box-shadow: #000000 0 10px 20px -10px;
            box-sizing: border-box;
            color: #ffffff;
            cursor: pointer;
            font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
                EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            opacity: 1;
            outline: 0 solid transparent;
            padding: 8px 18px;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            width: fit-content;
            word-break: break-word;
            border: 0;
            margin-bottom: 50px;
        }
    }

    @media (max-width: 1100px) {
        .navbar {
            > svg {
                margin-left: 0%;
            }
        }

        > form {
            .formContainer {
                .webpageContainer {
                    > div {
                        padding: 15px 25px;
                        textarea {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 900px) {
        .navbar {
            padding: 30px 3%;
            flex-direction: column;

            > svg {
                width: 100px;
                height: 100px;
            }
        }

        > form {
            margin-top: 290px;

            .formContainer {
                .webpageContainer {
                    > div {
                        flex-direction: column;
                        gap: 10px;

                        label {
                            margin-right: 0;
                            justify-content: center;
                        }

                        textarea {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .navbar {
            width: 100%;
            padding: 10px 10px;
            gap: 20px;

            > svg {
                width: 75px;
                height: 75px;
            }
        }

        form {
            margin-top: 310px;
        }
    }

    @media (max-width: 600px) {
        .navbar {
            width: 100%;
            padding: 10px 10px;
            gap: 5px;

            > svg {
                width: 75px;
                height: 75px;
            }
        }

        form {
            margin-top: 140px;
            .formContainer {
                width: 100%;
                border-radius: 0;
            }
        }
    }
}
