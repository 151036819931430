@import "../../styles/variables";

div {
    &.serviceCard {
        width: 300px;
        min-width: 300px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 8px;

        padding: 20px;
        margin: 0 15px 0 0;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        transition: transform 600ms, background-color 300ms, transform 250ms;
        box-shadow: 10px 10px 43px -19px rgb(0, 0, 0);

        text-align: left;
        color: #1b1b1b;
        line-height: 1.2;

        @include custom_scrollbar();

        &:last-child {
            margin: 0 0 0 0;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.8);
        }

        &:not(:first-child) {
            &:before {
                content: "";
                position: absolute;
                top: -20px;
                left: 50%;
                max-width: 2px;
                height: 50px;
                background-color: #fff;
            }
        }

        > p {
            font-size: 0.8rem;
            line-height: 1.5;
        }

        .calendar {
            display: flex;
            align-items: center;
            margin-top: 5px;

            > svg {
                width: 18px;
                height: auto;
                margin-right: 5px;
            }

            > span {
                font-size: 14px;
                margin-bottom: -2px;
            }
        }

        .image {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 16px;

            .imgCard {
                max-width: 100%;
                border-radius: 8%;
                padding: 8px;
            }
        }
        .cardBody {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;

            .heading {
                display: flex;
                justify-content: center;
                font-size: 1.4rem;
                font-weight: bold;
                line-height: 1.2;
            }

            p {
                max-height: 120px;
                font-size: 14px;
                margin: 10px 0;
                text-align: left;
                overflow-y: auto;
                padding-right: 5px;
                @include custom_scrollbar();
            }

            a {
                text-decoration: none;

                .primaryBtn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 13px 45px;
                    gap: 10px;
                    background-color: #e9eb95;
                    box-shadow: 10px 51px 94px rgba(0, 0, 0, 0.11), 10px 20.718px 33.6194px rgba(0, 0, 0, 0.0697538),
                        0px 11.8898px 16.44px rgba(0, 0, 0, 0.0506778), 0px 6.99366px 8.20599px rgba(0, 0, 0, 0.0353466),
                        0px 3.30696px 3.31776px rgba(0, 0, 0, 0.0200187);
                    border-radius: 40px;
                    border: 0;
                }
            }
        }

        &:before {
            width: 50px;
            height: 50px;
            background-color: #fff;
            margin: -30px;
        }
    }

    &.left {
        align-self: unset;
    }
}

@media (max-width: 768px) {
    div {
        &.serviceCard {
            &:hover {
                transform: unset;
            }
        }
    }
}
