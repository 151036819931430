@import "./reboot";
@import "./variables";

h1 {
    font-size: 48px;
}

p {
    font-size: 24px;
}
a {
    list-style: none;
}

@media #{$break-md} {
    h1 {
        font-size: 48px;
    }

    p {
        // font-size: 30px;
    }
}
