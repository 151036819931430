.signIn {
    width: 100dvw;
    height: 100dvh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 400px;
        height: 500px;
        box-shadow: 10px 14px 26px -6px rgba(71, 71, 71, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: #faaca8;
        background-image: linear-gradient(19deg, #faaca8 0%, #ddd6f3 100%);

        border-radius: 8px;

        svg {
            width: 150px;
            height: 150px;
        }

        > button {
            background-color: #fbda61;
            background-image: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);

            border-radius: 999px;
            box-shadow: #000000 0 10px 20px -10px;
            box-sizing: border-box;
            color: #ffffff;
            cursor: pointer;
            font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
                EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            opacity: 1;
            outline: 0 solid transparent;
            padding: 8px 18px;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            width: fit-content;
            word-break: break-word;
            border: 0;
        }
    }
}
