div.container {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .title {
        width: 500px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        animation-name: loading-animation;
        animation-iteration-count: infinite;
        animation-duration: 800ms;
        color: #fff;
    }

    > svg {
        width: 300px;
        height: 300px;
        animation-name: loading-animation;
        animation-iteration-count: infinite;
        animation-duration: 800ms;
    }
}

@keyframes loading-animation {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }

    from {
        opacity: 1;
    }
}
