@import "./variables";

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: $secondary_font;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    background-color: #faaca8;
    background-image: linear-gradient(19deg, #faaca8 0%, #ddd6f3 100%);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $primary_font;
    margin-top: 0;
    margin-bottom: 0;
}

p {
    font-family: $secondary_font;
    margin-top: 0;
    margin-bottom: 0;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

blockquote {
    margin: 0;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

select {
    word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

* {
    font-family: $secondary_font;

    html,
    body {
        overflow-x: hidden;
    }

    body {
        @include custom_scrollbar();

        ::-moz-selection {
            color: $white;
            background: $primary-pink;
        }

        ::selection {
            color: $white;
            background: $primary-pink;
        }

        h3,
        p {
            font-family: $secondary_font;
        }
    }

    .bold {
        font-weight: 700;
    }
}

button {
    color: #000;
}
