div.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .title {
        width: 300px;
        height: 75px;
        padding: 20px;
        border: 1px solid #fff;
        justify-self: flex-start;
        margin: 50px 0;
    }

    .errorContainer {
        width: 40%;
        height: 200px;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px;

        span {
            font-weight: bold;
            color: #000;
            text-align: center;
        }
    }

    @media (max-width: 800px) {
        .errorContainer {
            width: 80%;
        }
    }

    @media (max-width: 400px) {
        .errorContainer {
            width: 100%;
            border-radius: 0;
        }
    }
}
