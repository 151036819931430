.reviewCard {
    width: 350px;
    min-width: 350px;
    max-height: 200px;
    padding: 12px;
    display: flex;
    align-items: flex-start;
    gap: 1.2rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);

    .imgReview {
        max-width: 75px;
        border-radius: 100%;
    }

    .cardContent {
        display: flex;
        gap: 1rem;
        height: 100%;
        width: 100%;

        span i {
            font-size: 2rem;
            color: red;
        }

        .cardDetails {
            display: flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;

            p {
                font-size: 14px;
                margin-bottom: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
            }

            h4 {
                text-align: right;
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
}

// @media (max-width: 768px) {
//     div {
//         &.reviewCard {
//             width: 45%;
//             height: 100%;
//             margin: 0;

//             &:last-child {
//                 margin: 0 0 0 0;
//             }

//             .heading {
//                 font-size: 1.5rem;
//             }

//             p {
//                 font-size: 0.8rem;
//                 line-height: 1.5;
//             }
//         }

//         &.left {
//             align-self: unset;
//         }
//     }
// }

@media (max-width: 1300px) {
    div {
        &.reviewCard {
            width: 250px;
            height: 100%;
            min-width: 250px;
        }
    }
}

@media (max-width: 600px) {
    div {
        &.reviewCard {
            width: auto;
            height: 100%;
            min-width: 300px;
            margin: 0;

            &:last-child {
                margin: 0 0 0 0;
            }

            .heading {
                font-size: 1.5rem;
            }

            p {
                font-size: 0.8rem;
                line-height: 1.5;
            }
        }

        &.left {
            align-self: unset;
        }
    }
}
