@import "../../styles/variables";

main {
    overflow-x: hidden;
}

section {
    width: 100vw;
    margin-bottom: 0;
    border-top: 2px solid #fff;

    h2 {
        font-family: $primary_font;
        color: #000000;
        font-size: 60px;
        margin-left: -5vw;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;

        svg {
            width: 60px;
            height: auto;
            fill: #fff;
            margin-right: 20px;
        }
    }
}

.firstScreen {
    display: flex;
    flex-direction: column;

    height: 100%;

    border-top: 0px;

    @media #{$break-md} {
        height: 100%;
    }

    .imageContent {
        width: 100%;
        max-width: 100%;
        height: calc(100svh - 60px);
        overflow-x: hidden;
        display: flex;
        align-items: flex-end;

        .mobileImg {
            // width: 100%;
            height: 60%;
            display: block;
            object-fit: cover;

            @media #{$break-xs} {
                display: none;
            }
        }
        .mdImg {
            display: none;
            @media #{$break-xs} {
                width: 100%;
                height: 100vh;

                object-fit: cover;
                display: block;
            }
            @media #{$break-md} {
                display: none;
            }
        }

        .desktopImg {
            display: none;

            @media #{$break-md} {
                width: 100%;
                object-fit: cover;
                height: 100vh;
                display: block;
            }
        }
    }

    .titleSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        margin-bottom: 24px;
        padding: 0 60px;
        position: absolute;
        max-width: 960px;
        top: 10%;

        h1 {
            text-align: center;
            line-height: 110%;
            color: black;
            margin-bottom: 16px;

            @media #{$break-sm} {
                font-size: 45px;
            }
        }

        p {
            font-family: $secondary-font;
            text-align: center;
            color: $black;
            line-height: 110%;
            font-size: 18px;
            margin-bottom: 16px;
        }

        a {
            text-decoration: none;

            .primaryBtn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 13px 45px;
                gap: 10px;
                background-color: #e9eb95;
                box-shadow: 10px 51px 94px rgba(0, 0, 0, 0.11), 10px 20.718px 33.6194px rgba(0, 0, 0, 0.0697538), 0px 11.8898px 16.44px rgba(0, 0, 0, 0.0506778),
                    0px 6.99366px 8.20599px rgba(0, 0, 0, 0.0353466), 0px 3.30696px 3.31776px rgba(0, 0, 0, 0.0200187);
                border-radius: 40px;
                border: 0;
            }
        }

        @media #{$break-xs} {
            padding: 0 16px;
            max-width: 400px;
            top: 40%;
            left: 24px;

            p {
                font-size: 18px;
            }
        }

        @media #{$max-sm} {
            padding: 30px;

            h1 {
                font-size: 42px;
            }

            p {
                font-size: 16px;
            }
        }

        @media #{$break-sm} {
            max-width: 600px;

            p {
                font-size: 20px;
            }
        }

        @media #{$break-md} {
            padding: 0 16px;
            width: 650px;
            max-width: 650px;
            top: 25%;

            h1 {
                margin-bottom: 0;
            }

            p {
                left: 20%;
                top: 70px;
                margin-bottom: 0;
                min-width: 80%;
            }

            .primaryBtn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 13px 45px;
                gap: 10px;
                background: #ffffff;
                box-shadow: 10px 51px 94px rgba(0, 0, 0, 0.11), 10px 20.718px 33.6194px rgba(0, 0, 0, 0.0697538), 0px 11.8898px 16.44px rgba(0, 0, 0, 0.0506778),
                    0px 6.99366px 8.20599px rgba(0, 0, 0, 0.0353466), 0px 3.30696px 3.31776px rgba(0, 0, 0, 0.0200187);
                border-radius: 40px;

                &:focus {
                    background-color: $primary_yellow;
                }
            }
        }

        @media #{$break-lg} {
            margin-bottom: 2vw;
            padding: 0 8vw;
            padding: 0 16px;
            max-width: 600px;
            min-width: 600px;

            h1 {
                font-size: 50px;
            }
            p {
                font-size: 20px;
                width: 100%;
            }
        }

        @media #{$break-xxl} {
            width: 100%;
            max-width: 650px;
            left: 10%;
        }

        @media #{$break-xxxl} {
            width: 100%;
            max-width: 800px;

            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 1.3vw;
                top: 5.9vw;
            }
        }
    }

    .arrow {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 50%;
        top: 95%;
        z-index: 1;
        fill: #000;
        animation-name: arrow-animation;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
    }
}

.aboutUs {
    display: flex;
    flex-direction: column-reverse;

    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 24px 48px;
    overflow: hidden;

    border-top: 0px;

    .image {
        display: block;
        width: 300px;
        height: 300px;
        border-radius: 10%;
        object-fit: cover;
        pointer-events: none;

        @media #{$break-md} {
            width: 400px;
            height: 400px;
        }
    }

    .information {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        h2 {
            color: $black;
            margin-left: 0;
            width: 100%;
        }

        p {
            font-family: $secondary-font;
            text-align: left;
            color: $black;
            line-height: 110%;
            font-size: 18px;
            margin-bottom: 16px;
            min-height: 260px;
            overflow-y: auto;
            padding-right: 5px;
            @include custom_scrollbar();
        }

        @media #{$break-md} {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            padding: 30px;

            h2 {
                margin-left: 0;
                margin-bottom: 0;
            }

            p {
                margin-top: 20px;
                width: 100%;
                font-size: 1.2rem;
                line-height: 1.7;
                color: $black;
            }
        }
    }

    @media #{$break-md} {
        flex-direction: row;
        height: 100vh;
    }
}

.ourServices {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ff9a8b;
    background-image: linear-gradient(90deg, #ff9a8b 0%, #ff6a88 55%, #ff99ac 100%);
    padding: 32px;

    h2 {
        width: 100%;
        margin-top: 32px;
        margin-left: 0;
    }

    .serviceList {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: nowrap;

        margin: 0 8px;
        margin-bottom: 30px;
        padding: 10px 30px 10px 30px;

        @include custom_scrollbar();
    }
}

.curso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;

    background-color: #ff6a88;
    font-size: 18px;
    padding: 40px;

    h2 {
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
        text-align: center;
    }

    > p {
        text-align: center;
        max-width: 1200px;
    }

    a {
        text-decoration: none;

        .primaryBtn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 13px 45px;
            gap: 10px;
            background-color: #e9eb95;
            box-shadow: 10px 51px 94px rgba(0, 0, 0, 0.11), 10px 20.718px 33.6194px rgba(0, 0, 0, 0.0697538), 0px 11.8898px 16.44px rgba(0, 0, 0, 0.0506778),
                0px 6.99366px 8.20599px rgba(0, 0, 0, 0.0353466), 0px 3.30696px 3.31776px rgba(0, 0, 0, 0.0200187);
            border-radius: 40px;
            border: 0;
            font-weight: bold;
        }
    }
}

.onlineShop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 30vh;
    background-color: #f2afff;

    .onlineShopButton {
        padding: 20px 30px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dd33ff;
        border: 1px solid #fff;
        color: #fff;
        font-size: 1.5rem;
        cursor: pointer;

        &:hover {
            background-color: #e76cff;
        }
    }
}

.reviews {
    display: flex;
    width: 100%;
    height: 100dvh;
    min-height: 70vh;
    flex-direction: column;
    justify-content: center;
    background-color: #f71cdf;
    //background-image: linear-gradient(19deg, #f71cdf 0%, #ebd3f1 100%);
    background-image: linear-gradient(120deg, #ff99ac 0%, #ff6a88 55%, #ff9a8b 1000%);
    align-items: center;

    h2 {
        width: 100%;
        margin-top: 32px;
        margin-left: 0;
        color: #000;
    }

    .serviceList {
        display: flex;
        flex-direction: row;
        gap: 40px;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;
        padding: 20px;
        @include custom_scrollbar();
    }
}

.getInTouch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
    // background-color: #f2afff;
    padding: 32px;
}

.whatsapp {
    position: fixed;
    bottom: 40px;
    right: 40px;

    border-radius: 50%;
    background-color: #36c138;

    width: 60px;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 30px;
        height: 30px;
        fill: #fff;
    }
}

@media (max-width: 1300px) {
    .reviews {
        padding: 42px;
        min-height: unset;
        gap: 20px;
        height: 150%;
    }

    .reviews,
    .ourServices {
        .serviceList {
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 10px;
        }
    }
}

@media (max-width: 1024px) {
    .aboutMe {
        .information {
            flex-direction: column;

            .image {
                width: 300px;
                height: 300px;
                align-self: center;
            }

            p {
                margin-left: 0;
                margin-top: 20px;
                text-align: center;
            }
        }
    }

    .curso {
        gap: 40px;

        > h2 {
            font-size: 50px;
        }

        > p {
            font-size: 22px;
        }
    }
}

@media (max-width: 768px) {
    .curso {
        > h2 {
            font-size: 40px;
        }

        > p {
            font-size: 18px;
        }
    }
}

@media (max-width: 600px) {
    section {
        h2 {
            font-size: 42px;
            text-align: center;
        }
    }

    .aboutUs {
        .information {
            p {
                font-size: 16px;
            }
        }
    }

    .curso {
        height: unset;
        padding: 40px;
        gap: 30px;

        > h2 {
            font-size: 35px;
        }

        > p {
            text-align: center;
            font-size: 18px;
        }
    }

    .getInTouch {
        padding: 0;
        height: 100%;
    }
}

.fadeTop {
    opacity: 0;
    transform: translate(0, 10vh);
    transition: opacity 1s, transform 1s;
}

.fadeRight {
    opacity: 0;
    transform: translate(10vh, 0vh);
    transition: opacity 1s, transform 1s;
}

.fadeLeft {
    opacity: 0;
    transform: translate(-10vh, 0vh);
    transition: opacity 1s, transform 1s;
}

.visible {
    opacity: 1;
    transform: translate(0, 0);
}

@keyframes arrow-animation {
    0% {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(0, -20px, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}
